.how-it-works-point {
  margin-top: -12px;
  text-align: center;
}

.how-it-works-point > p {
  margin: 8px 0;
}

.how-it-works-title {
  padding-bottom: 24px;
}

.bucket-rows {
  margin-top: -48px;
}

.howitworks {
  padding-top: 16px;
  margin-top: 56px;
}

.how-to-card > div > img {
  max-width: 90%;
}
