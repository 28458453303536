.location-logo {
  align-self: center;
  max-height: 136px;
  max-width: 100%;
}
.location {
  & .location-logo {
    text-align: center;
    align-items: center;
    flex-direction: row;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    justify-content: space-around;

    & img {
      max-width: 100%;
    }
    @media all and (max-width: 768px) {
      flex-direction: column;
    }

    @media all and (max-width: 768px) {
      margin: 0 auto 16px;
    }
  }

  & .location-info {
    width: 100%;

    & .location-info_title {
      text-align: center;
      font-weight: 600;
      font-size: 32px;
    }
  }

  & .location-content_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  & .location-order {
    display: flex;
    padding-bottom: 16px;

    & .location-order_icon {
      color: var(--nu-tertiary);
      font-size: 26px;
      margin: 0 24px 0 4px;
    }
    & .location-order_number {
      align-self: center;
    }
  }

  & .location-action {
    text-align: center;
  }

  & .loc-three-row {
    padding-bottom: 8px;
  }

  & .location-status {
    display: flex;
    justify-content: left;

    & button,
    & a.button {
      margin-bottom: 0;
      padding-bottom: 0;
    }

    & .location-status_icon {
      color: var(--nu-tertiary);
      font-size: 32px;
      font-weight: 600;
      margin: 7px 16px 0 0;
    }

    & .location-status_description {
      display: inline-block;
      align-self: center;
      font-size: 18px;
      font-weight: 600;
    }

    &.location-status_placed {
      color: green;
      & .location-status_icon {
        color: green;
      }
    }

    & .location-status_text {
      align-self: center;
      font-size: 20px;
    }
  }

  & .location-building {
    display: flex;
    padding-bottom: 16px;
    align-items: center;

    & .location-building_icon {
      color: var(--nu-tertiary);
      font-size: 32px;
      margin: 0 16px 0 0;
    }

    & .location-building_address {
      font-style: italic;
      font-size: 16px;

      & .location-building_address-name {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        font-style: normal;
        & button {
          margin-bottom: -10px;
          min-height: 0px;
        }
      }

      & .location-building_address-street {
        text-align: left;
      }

      & .location-building_address-citystatezip {
        text-align: left;
      }
    }
  }

  & .location-pickup {
    display: flex;
    padding-bottom: 16px;
    align-items: center;

    & .location-pickup_icon {
      color: var(--nu-tertiary);
      font-size: 32px;
      margin: 0 16px 0 0;
    }
    & .location-pickup_description {
      align-self: center;
    }
  }

  & .location-time {
    display: flex;
    padding-bottom: 16px;
    align-items: center;

    & .location-time_icon {
      color: var(--nu-tertiary);
      font-size: 28px;
      font-weight: 600;
      margin: 0 16px 0 0;
      text-align: left;
    }

    & .location-time_datetime {
      display: inline-block;
      align-self: center;
    }
  }
  & .location-credits {
    display: flex;
    padding-bottom: 16px;
    align-items: center;

    & .location-credits_icon {
      color: var(--nu-tertiary);
      font-size: 32px;
      width: 32px;
      text-align: center;
      margin: 0 16px 0 0;
      font-weight: 600;
    }

    & .location-credits_label {
      align-self: center;
    }
  }
}

.status-message {
  font-weight: normal;
  margin-top: 8px;
}

.order-status {
  & .location-status_icon {
    color: var(--nu-tertiary);
    font-size: 32px;
    font-weight: 600;
    margin: 7px 16px 0 0;

    & i {
      vertical-align: middle;
    }
  }
}

.successIcon {
  color: #9fcb3a;
}
