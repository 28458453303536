.confirmAcctAddress {
  text-align: left;
  position: relative;
}

.disabledEmailInput::placeholder {
  color: #c1c1c1;
}

.completeAccountPassword[type='password']::placeholder {
  letter-spacing: 1px !important;
}

@media screen and (min-width: 600px) {
  .foodsby-logo {
    background-image: url('https://images.foodsby.com/www/theme/download.png');
    width: 211px;
    height: 76px;
    background-repeat: no-repeat;
  }
}

@media screen and (max-width: 599px) {
  .simple-layout {
    & .header-dropdown {
      width: 100%;
    }
  }

  .confirmAcctAddress {
    flex-wrap: wrap;
    margin-top: -20px;
    max-width: 427px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .confirmAcctAddress::before {
    content: '';
    background-image: url('https://images.foodsby.com/www/theme/download.png');
    height: 36px;
    width: 100px;
    background-size: cover;
    margin-right: 16px;
    position: relative;
    top: 0px;
    left: 0px;
  }
}

@media screen and (max-width: 352px) {
  .simple-layout {
    & .header-large {
      flex-basis: 124px !important;
    }
  }
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .completeAccountPassword[type='password'] {
    letter-spacing: 1px !important;
  }
}
