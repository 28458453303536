.autocomplete-root {
  position: relative;
  font-weight: normal;
  font-size: 16px;
  font-style: normal;
}
.autocomplete-dropdown-container {
  position: absolute;
  z-index: 3;
  background: white;
  width: 100%;
  top: 42px;
  left: 0px;
}
.autocomplete-dropdown-container__active {
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.12);
}
.autocomplete-suggestion {
  font-size: 15px;
  text-align: left;
  padding: 12px 8px;
  cursor: pointer;

  &:hover,
  &:active {
    color: var(--nu-primary-alt);
    background-color: var(--nu-light-grey-blue);
    transition: background, color, var(--nu-transition);
  }
}

.autocomplete-suggestion__active {
  color: var(--nu-primary-alt);
  background-color: var(--nu-light-grey-blue);
  transition: background, color, var(--nu-transition);
}
