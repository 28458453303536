.segment {
  & .segment-description {
    text-align: center;
  }
  & .segment-title {
    font-size: 20px;
    padding: 16px 0;
  }
  & .segment-items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0;
    padding: 16px 0;

    & li {
      list-style: none;
      display: inline-block;
      margin: 0 4px 0 0;
      width: calc(((1 / 3) * 100%) - 4px);

      @media all and (max-width: 768px) {
        flex: 0 0 100%;
      }
    }

    & .segment-item_addon {
      display: block;
      color: var(--nu-grey);
      font-size: 14px;
    }
  }
  &.segment-child {
    padding-left: 16px;
    border-left: 1px solid grey;
  }
  &.segment-optional {
    & .segment-title {
      &:hover {
        color: var(--nu-secondary);
        cursor: pointer;
        &:after {
          border-top: 7px solid var(--nu-secondary);
        }
      }
    }
    & .segment-title:after {
      content: '';
      width: 10px;
      margin-left: 5px;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
      border-top: 7px solid #30332c;
      display: inline-block;
    }

    &.segment-optional_open {
      & .segment-title:after {
        border-bottom: 7px solid #30332c;
        border-top: 0;
      }

      & .segment-title:hover {
        &:after {
          border-bottom: 7px solid var(--nu-secondary);
        }
      }
    }
  }

  & .segment-text {
    font-size: 18px;
    padding: 6px 12px;
    color: black;
    height: 42px;
  }

  & .segment-push,
  &.segment-push {
    margin-top: 16px;
    padding-top: 16px;
  }
}
