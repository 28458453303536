.confirm {
  font-size: 24px !important;
}

.orderHistoryStatus {
  font-size: 22px;
}

.preferMobile {
  margin-bottom: 4px;
  font-size: 24px;
  font-weight: 600;
}

.logo-container {
  border-right: 1px solid lightgrey;

  @media screen and (max-width: 1023px) {
    border-right: none;
    border-bottom: 1px solid lightgrey;
  }

  & .location-logo {
    margin-bottom: 32px;
  }

  & .order-info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: left;
    margin: 16px;

    & > .row {
      flex: 1 1 auto;
    }

    & p {
      margin-bottom: 8px;
    }

    & .showMoreBtn {
      display: block;
      margin-top: 32px;
      text-align: left;

      @media screen and (min-width: 1024px) {
        text-align: center;
      }
    }
  }
}

.location-info-container {
  align-self: center;

  @media screen and (max-width: 1024px) {
    margin-top: 32px;
  }

  & > div {
    margin-bottom: 8px;
    margin-left: 24px;
  }
}

.confirmation-contact-us {
  @media screen and (max-width: 1024px) {
    padding-top: 16px;
  }
}
