@media screen and (min-width: 1024px) {
  .first-panel {
    padding-right: 32px;
  }

  .second-panel {
    padding-left: 32px;
    border-left: 1px solid #bfbfbf;
  }
}

@media screen and (max-width: 1024px) {
  .first-panel {
    padding-bottom: 32px;
  }

  .second-panel {
    border-top: 1px solid #bfbfbf;
  }
}

.Link--is-inActive {
  pointer-events: none;
}

.createAccountForm {
  & input {
    background-color: white;
  }
}

.date-dropdown--control {
  background-color: transparent;
}

.date-dropdown {
  &__control {
    height: 50px;
    display: flex;
    justify-content: space-between;
    padding: 8px;
    background-color: transparent;
    border: none;
    font-size: 1.5em;
    &:hover {
      box-shadow: none;
      background-color: rgba(48, 51, 44, 0.04);
      cursor: pointer;
      border-radius: 4px;
    }
  }

  &__menu {
    border-radius: 8px;
    max-height: none;
    & .Dropdown-option {
      color: 'black';
    }
    & .Dropdown-option.is-selected {
      background-color: var(--nu-light-grey);
    }
    & .Dropdown-option:hover {
      background-color: var(--nu-light-grey);
    }
  }

  &__placeholder {
    padding-top: 2px;
  }
}
