@font-face {
  font-family: 'icomoon';
  src: url(../fonts/icomoon.woff2?oks8ga) format('woff2'),
    url(../fonts/icomoon.ttf?oks8ga) format('truetype'),
    url(../fonts/icomoon.woff?oks8ga) format('woff'),
    url(../fonts/icomoon.svg?oks8ga#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lock:before {
  content: '\f023';
}
.icon-times-circle:before {
  content: '\f057';
}
.icon-check-circle:before {
  content: '\f058';
}
.icon-question-circle:before {
  content: '\f059';
}
.icon-info-circle:before {
  content: '\f05a';
}
.icon-exclamation-circle:before {
  content: '\f06a';
}
.icon-exclamation-triangle:before {
  content: '\f071';
}
.icon-warning:before {
  content: '\f071';
}
.icon-envelope:before {
  content: '\f0e0';
}
.icon-angle-up:before {
  content: '\f106';
}
.icon-chevron-circle-down:before {
  content: '\f13a';
}
.icon-dollar:before {
  content: '\f155';
}
.icon-home:before {
  content: '\e902';
}
.icon-office:before {
  content: '\e903';
}
.icon-pencil:before {
  content: '\e905';
}
.icon-cart:before {
  content: '\e93a';
}
.icon-credit-card:before {
  content: '\e93f';
}
.icon-phone:before {
  content: '\e942';
}
.icon-location:before {
  content: '\e947';
}
.icon-clock:before {
  content: '\e94e';
}
.icon-calendar:before {
  content: '\e953';
}
.icon-printer:before {
  content: '\e954';
}
.icon-mobile:before {
  content: '\e958';
}
.icon-user:before {
  content: '\e971';
}
.icon-users:before {
  content: '\e972';
}
.icon-stats-bars:before {
  content: '\e99c';
}
.icon-gift:before {
  content: '\e99f';
}
.icon-trash:before {
  content: '\e9ac';
}
.icon-menu-move:before {
  content: '\e9be';
}
.icon-menu-down:before {
  content: '\e9bf';
}
.icon-menu-up:before {
  content: '\e9c0';
}
.icon-star-empty:before {
  content: '\e9d7';
}
.icon-star-full:before {
  content: '\e9d9';
}
.icon-checkmark:before {
  content: '\ea10';
}
.icon-back:before {
  content: '\ea38';
}
