.coming-soon-content {
  & h4 {
    margin-bottom: 24px;
  }

  & .coming-soon-hr {
    margin: 32px 0px;
  }

  & .coming-soon-title {
    margin-bottom: 16px;
  }

  & .coming-soon-subtitle {
    margin: 8px 16px;
    font-size: 20px;
  }

  & #coming-soon-day-button {
    font-size: 24px;
    font-weight: 700;
    text-decoration: underline;
  }

  & .prefer-mobile {
    margin-top: 24px;
  }

  & .join-form {
    margin-top: 16px;
  }
}
