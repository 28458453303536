.autocomplete-wrapper {
  position: relative;
  & .autocomplete-dropdown {
    position: absolute;
    margin-top: calc(-1 * var(--gutter));
    background: white;
    border: 1px solid var(--nu-grey);
    border-top: 0px;
    width: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;

    & button {
      height: 24px;
      font-size: 16px;
      text-align: left;
      border: 0px;
      border-radius: 0px;
      margin: 0;
      background: white;
      color: black;
      letter-spacing: 1px;
      font-weight: 400;
      padding: 8px;

      &:after {
        border: none;
        left: calc(100% - 16px);
        top: 10px;
        padding-right: 8px;
      }
      &:hover,
      &:focus {
        background: var(--nu-light-grey);
      }
      &[data-selected='true'] {
        color: var(--nu-secondary);
      }
      &[data-selected='true']:after {
        content: '✓';
        position: absolute;
        color: var(--nu-secondary);
      }
    }
  }
}

.google-attribute {
  max-width: 144px;

  &:hover,
  &:active {
    background-color: #fff !important;
    cursor: default;
  }
}
