@media print {
  body {
    background-color: transparent;
  }

  header,
  .nav,
  .aside,
  footer,
  .button,
  .dropdown,
  .cart-description,
  .cart .cart-description,
  .order-history-table,
  .contact-us-sidebar,
  .cart-item_mod_title,
  .cart-item_mod_price,
  .referral-card,
  .referral-container,
  .OrderSummary-printReceipt button.cart-checkout,
  .friendsPanelContainer,
  #webWidget,
  #launcher {
    display: none !important;
    box-shadow: none;
  }
  .article {
    height: 100%;
    width: 100%;
  }
  .card {
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 0;
  }

  .Collapsible__contentOuter {
    height: auto;
  }

  li,
  .location-status > span,
  .location-building > span,
  .location-building > div,
  .location-building_address-name,
  .location-time_time,
  .location-time > span,
  .location-order_icon,
  .main {
    font-size: 75% !important;
  }
}
