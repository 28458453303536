@import './root.css';
@import './layout.css';
@import './base.css';

@import './Confirmation.css';
@import './FoodsbyV3.css';
@import './HowItWorks.css';
@import './LocationSearchBox.css';
@import './OrderHistory.css';
@import './autocomplete.css';
@import './cart.css';
@import './comingsoon.css';
@import './completeaccount.css';
@import './credits.css';
@import './friends.css';
@import './home.css';
@import './icons.css';
@import './location.css';
@import './notification.css';
@import './panel.css';
@import './placesautocomplete.css';
@import './print.css';
@import './profile.css';
@import './referral.css';
@import './restaurantlogin.css';
@import './restaurantshowcase.css';
@import './segment.css';

.error {
  color: red;
}

.warning {
  color: #fdbb11;
}
