/* Main is in Nutrient V2 */
.main {
  /* Main Content */
  & > .content {
    padding-bottom: 32px;
    justify-content: center;

    @media screen and (min-width: 768px) {
      flex-direction: row;
      display: flex;
      justify-content: center;
      flex: 1 0 auto;
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
      flex: 1 0 auto;
    }

    & > .article {
      order: 1;
      overflow: visible;

      & > div {
        margin: 0 0 32px 0;
      }

      @media screen and (max-width: 768px) {
        flex: 1 0 100%;
      }

      @media screen and (min-width: 768px) {
        flex: 0 1 768px;
      }
    }

    & > .article-lg {
      flex: 0 1 1200px;
      padding: 0;
      order: 1;

      & > div {
        margin: 0 0 32px 0;
      }

      @media screen and (max-width: 768px) {
        flex: 1 0 100%;
      }
    }

    & > .aside {
      flex: 0 1 384px;
      padding-left: 23px;
      order: 2;

      @media screen and (max-width: 768px) {
        flex: 1 0 100%;
        margin: 0;
        padding-left: 0;
      }

      & > div {
        margin: 0 0 32px 0;
      }
    }

    /* Side Nav */
    & > .nav {
      flex: 0 1 232px;
      padding-right: 16px;

      @media screen and (max-width: 768px) {
        flex: 1 0 100%;
      }

      & ul {
        list-style-type: none;

        & > li {
          color: var(--nu-grey);
          font-weight: 500;
          text-transform: uppercase;
          margin: 0 0 0 16px;

          & > a {
            & > button {
              text-align: left;
              font-size: 18px;
            }
          }

          &:first-child {
            margin-bottom: 16px;
          }

          & a,
          & button {
            font-size: 18px;
            font-weight: normal;
            margin: 0;
            text-decoration: none;
            text-transform: none;
            &.active {
            }

            &.active::before {
              content: '';
              width: 12px;
              margin-left: -12px;
              border-top: 4px solid transparent;
              border-bottom: 4px solid transparent;
              border-left: 6px solid var(--nu-primary);
              display: inline-block;
            }
          }
        }
      }
    }
  }

  /* Footer */
  & > .footer {
    background-color: white;
    text-align: center;
    width: 100%;
    max-width: 100%;
    z-index: 2;
    display: block;

    & > .footer-logo {
      border-bottom: 1px solid var(--nu-light-grey);
      padding: 30px 0;
      width: 100%;
      text-align: center;
    }

    & > .footer-wrapper {
      box-shadow: 0 -4px 4px rgba(50, 50, 50, 0.05);
      position: relative;
      padding: 16px 0;
      width: 100%;
      text-align: center;

      & > .footer-nav {
        & > ul {
          padding: 0;
          margin: 0;
          list-style: none;
          position: relative;

          & > li {
            padding: 8px;
            margin: 0;
            list-style: none;
            display: inline-block;

            & > a {
              padding: 0px;
            }
          }
        }
      }

      & > .footer-up {
        position: absolute;
        background: var(--nu-secondary);
        top: 20px;
        right: 30px;
        min-height: 30px;
        height: 30px;
        width: 30px;
        box-shadow: 2px 2px 2px 0px var(--nu-base);
        color: white;

        @media screen and (max-width: 768px) {
          top: -57px;
          box-shadow: 4px 4px 6px rgba(50, 50, 50, 0.5);
        }

        &:hover {
          background: var(--nu-secondary);
          color: white;
          text-decoration: none;
        }
      }

      & .app-icon {
        display: inline-block;
        margin: 15px 6px 0;
      }
    }
  }
}

.simpleContent {
  background-size: cover;
  background-position: center;
  align-items: center;
  padding-bottom: 0px !important;
  margin-top: 0px !important;
}

.simple-footer-wrapper {
  min-height: 100px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  padding: 30px;
  align-self: flex-end;
  text-align: center;
}

.simple-footer-text {
  font-size: 40px;
  font-weight: 400;
}

.simple-footer-bullet {
  font-size: 14px;
  display: inline-block !important;
  margin-left: 5px !important;
  margin-right: 5px !important;
  vertical-align: middle;
  padding-bottom: 7px;
}

@media (max-width: 1060px) {
  .simple-footer-text {
    font-size: 24px;
  }
}

.ContainerGutters {
  padding-left: calc(4px * 3);
  padding-right: calc(4px * 3);
}

@media screen and (min-width: 768px) {
  .ContainerGutters {
    padding-left: 0;
    padding-right: 0;
  }
}
