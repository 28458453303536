.cart {
  & > ul {
    padding: 0;
    margin: 0;

    & > li.cart-item {
      padding: 0;
      padding-bottom: 8px;
      margin: 0;
      list-style: none;
      padding-top: 8px;
      font-size: 16px;
      & .cart-item_title {
        font-weight: 600;
        margin-bottom: 8px;
        display: inline-block;
      }
      & .cart-item_none {
        font-weight: 400;
        margin-bottom: 8px;
        display: inline-block;
        font-style: italic;
      }
      & .cart-item_addon {
        display: block;
        padding-left: 8px;
        color: var(--nu-grey);
      }
      & .cart-item_summary {
        display: flex;
        justify-content: space-between;
        padding-top: 5px;
      }
      & .cart-item_description {
        margin-bottom: 0;
        position: relative;
      }
      & .cart-item_price {
        margin-bottom: 8px;
        position: relative;
      }

      & a.cart-item_delete {
        right: -20px;
        font-size: 16px;
      }

      & a.cart-item_edit {
        font-size: 16px;
      }

      & > ul {
        margin-bottom: 16px;
        & > li {
          list-style: none;
          margin-bottom: 8px;

          &:before {
            content: '-';
            padding-right: 4px;
          }
        }
      }
    }
  }
  & .cart-subtotal {
    display: flex;
    justify-content: space-between;
    padding-bottom: 8px;
  }
  & .cart-credits,
  & .cart-debits {
    padding-bottom: 16px;
  }
  & .cart-total {
    display: flex;
    justify-content: space-between;
    padding-bottom: 16px;
    font-weight: 600;
  }
  & .cart-description {
    max-width: 220px;
    padding: 32px 0;
    display: inline-block;
  }
}

.cart-item_modifier_list {
  margin: 8px 0 0 16px;
}

.cart-item_modifier_list li {
  font-size: 16px;
  list-style: none;
  margin-bottom: 8px;
}

.cart-item_modifier_list li:before {
  content: '- ';
}

.cart-special_instructions {
  word-break: break-all;
  word-wrap: break-word;
  display: inline-block;
  margin-top: 4px;
}

.cart-item_delete {
  float: right;
}
.cart-item_instructions {
  font-size: 14px;
  margin-top: 16px;
}

.cart-item_favorite {
  position: absolute;
  margin-left: -28px;
  margin-top: 3px;
}
