.panel {
  text-align: left;
  display: block;

  & .panel-title {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
    align-self: center;
  }

  & .panel-form {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
  }

  & .panel-description {
    margin-top: 16px;
    min-width: 100%;
  }

  & .panel-options {
    text-align: left;
  }

  & .panel-submit {
    align-self: center;
  }

  & .panel-action {
    display: inline-block;
    align-self: center;
    margin-bottom: 16px;
  }
}
