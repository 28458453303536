/* 
* This CSS file is used to namespace future brand work while we are still waiting to roll new components into
* Nutrient. For this reason, this file is quite large.
*
* Index
* Theme base styles
* Typography 
* Form Patterns
* * Checkboxes
* * Inputs
* * Select 
* * Forms
** layout
*/
/* Theme base styles */

h1,
h2,
h3,
h4,
h5,
h6,
p,
div,
em,
.TextSub,
.TextFine {
  font-family: 'Roboto', sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
em,
.TextSub,
.TextFine {
  color: #30332c;
}
span {
  font-family: 'Roboto', sans-serif;
}

a:not(.MuiLink-root):not(.MuiButtonBase-root) {
  color: #3d4a6b;
  cursor: pointer;
  text-decoration: underline;

  &:hover {
    color: #36415f !important;
  }
  &:focus {
    text-decoration: underline 0 0 6px 0 rgba(61, 74, 107, 0.7);
    outline: none;
  }
  &:active {
    color: #3d4a6b;
    text-decoration: underline;
  }
  &[disabled],
  &.disabled {
    color: #abadb2;
    cursor: not-allowed;
    user-select: none;
  }
  &.destructive {
    color: #d7522f;

    &:active {
      color: #ab2b19;
    }
    &:hover {
      color: #ab2b19;
    }
  }
}

h1 .TextAlt {
  font-family: futura-pt, sans-serif;
  font-size: 96px;
  font-weight: bold;
}

h1 .TextAlt {
  font-weight: bold;
}
/* TODO should be 60px but needs regressions */
h2 {
  font-size: 40px;
}

h2 .TextAlt {
  font-weight: bold;
}

h3 {
  font-size: 48px;
}

h3 .TextAlt {
  font-weight: bold;
}

h4 {
  font-size: 34px;
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 20px;
}
p {
  font-size: 16px;
}

.TextSub {
  font-size: 12px;
}
.TextFine {
  font-size: 10px;
}

/* Forms */

/* CheckBox */
.Checkbox {
  position: relative;
  padding-bottom: 4px;

  & .Checkbox-title {
    display: inline-block;
    font-size: 14px;
    margin-bottom: 8px;
    position: relative;
  }

  & .subscription-is-trial {
    color: #626366 !important;
  }

  & .subscription-is-trial:after {
    border: 1px solid #d3d6de;
    border-radius: 4px;
    background-color: #d3d6de;
    color: #35393d;
    content: 'FREE TRIAL';
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: bold;
    left: 215px;
    padding: 4px 8px;
    position: absolute;
    top: 16px;
  }

  & label {
    color: #30332c;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    margin-bottom: 8px !important;

    &::before {
      content: '\EA10' !important;
      font-family: icomoon !important;
      font-size: 11px;
      margin-right: 8px !important;
    }
  }

  & input {
    display: none;
  }

  & .checkbox {
    padding-bottom: 0;
  }

  & .Checkbox-hint {
    border-bottom: 1px solid transparent;
    color: #30332c;
    display: block;
    font-size: 14px;
    line-height: 1.2;
    margin-left: 26px;
    padding-bottom: 8px;
    transition: border 0.1s;
  }

  & .Checkbox-success {
    color: transparent;
    display: block;
    font-size: 14px;
    margin-left: 26px;
    overflow: hidden;
    padding-top: 4px;
    position: absolute;
    transition: color 0.1s;

    &:before {
      color: transparent;
      content: '\EA10';
      display: inline;
      font-family: icomoon !important;
      font-feature-settings: normal;
      font-style: normal;
      font-variant: normal;
      font-weight: 400;
      left: 12px;
      line-height: 1;
      speak: none;
      text-transform: none;
      transition: inherit;
    }
  }

  & .Checkbox-error {
    color: transparent;
    display: block;
    font-size: 14px;
    margin-left: 26px;
    overflow: hidden;
    padding-top: 4px;
    position: absolute;
    transition: color 0.1s;
  }

  &.is-checked {
    & .Checkbox-hint {
      border-bottom: 1px solid #9fcb3a;
    }

    & .subscription-is-trial {
      border-bottom: 4px solid #6e8d28;
      border-radius: 1px;
    }

    & .Checkbox-success {
      color: #30332c;

      &:before {
        color: #6e8d28;
        font-family: 'Material Icons' !important;
        content: 'check_circle';
        font-size: 18px;
      }
    }

    & .Checkbox-error {
      color: #ee3c23;
    }
  }

  &.is-error {
    & .Checkbox-hint {
      border-bottom: 1px solid transparent;
    }
  }

  &.is-subscribed {
    & .checkbox:after {
      display: none;
    }
  }

  &.is-disabled {
    & label {
      color: inherit !important;
      cursor: not-allowed !important;
      &::before {
        background-color: #949494 !important;
        border-color: #949494 !important;
      }
    }
  }
}

/* Input */
.Input {
  /* Input Modifiers */
  &.Input--attached {
    & .Input--attachedSet {
      align-items: center;
      display: flex;
      flex-grow: 1;
      justify-content: center;
      justify-content: space-between;
      margin-bottom: 8px;
      max-width: 100%;
      min-height: auto;
    }

    & input {
      border-color: lightgrey;
      border-radius: 4px 0 0 4px !important;
      border-style: solid;
      border-width: 1px 0px 1px 1px !important;
      margin-bottom: 0;
      width: 100%;
    }

    & button {
      height: 40px;
      border-color: lightgrey;
      border-style: solid;
      border-width: 1px !important;
      border-radius: 0 4px 4px 0 !important;
      margin: 0;
      min-width: auto;
      white-space: nowrap;
    }
  }
  /* Input States */
  &.is-inactive {
    & label {
      display: block;
      margin-bottom: 20px !important;
    }

    & p {
      font-size: 16px;
    }

    & .Input--attachedSet {
      border: none;
      margin-bottom: 12px;
    }

    /* adds back border radious */
    & button {
      border-radius: inherit;
      margin-top: -16px;
    }
  }
}

/* Select  */
.Select {
  margin-bottom: 16px;

  & label {
    color: #30332c;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    margin-bottom: 4px;
  }

  /* Nutrient Select Overrides. */
  & .nutrient__control {
    border: 1px solid lightgray !important;
    border-radius: 25px !important;
    height: 40px;
    overflow: hidden;
  }

  & .nutrient__indicator-separator {
    display: none;
  }
}

/* Hacks to handel NutrientV2 overrides */
& p,
& a {
  font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif !important;
  line-height: initial;
}
a:hover {
  text-decoration: none !important;
}
/* Hack for global a styles */
& .no-hover:hover {
  color: #30332c;
}

& li {
  margin-bottom: initial;
  list-style: none;
}

.main {
  height: auto !important;
  min-height: 100vh;
  background-color: #f5f5f5;
}

/* Tables  */
table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  border-collapse: collapse;
  padding: 8px 16px;
  border-bottom: 1px solid lightgrey;
  white-space: nowrap;
  text-align: left;
}
tbody tr {
  &.selected {
    color: var(--nu-primary-alt);
    background-color: var(--nu-light-grey-blue);
    transition: background, color, var(--nu-transition);
  }
}

.table-hover tbody tr {
  &:hover {
    color: var(--nu-primary-alt);
    background-color: var(--nu-light-grey-blue);
    transition: background, color, var(--nu-transition);
    cursor: pointer;
  }
}

.segment-items .Checkbox label {
  font-size: 16px !important;
}

.action-btn {
  color: var(--nu-secondary) !important;
}

footer {
  & a {
    text-decoration: none !important;
  }
}
