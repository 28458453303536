:root {
  --base-friends: #494a4c;
  --red-friends: #d43620;
  --red-alt-friends: #ee3c23;
  --main-ocean-blue: #27365b;
  --ocean: #00838f;
  --cyan: #007680;
  --cyan-dark: #005d67;
  --autocomplete-base: #f2f2f2;
  --new-friend-base: #abadb2;
}

.friendsTitle {
  text-align: center;

  & > i {
    font-size: 32px;
  }
  & > a {
    font-size: 36px;
    font-weight: 600;
  }
}

.friendEmail {
  font-size: 14px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: no-wrap;
}

.friendLink {
  margin-right: 4px;
  margin-left: 4px;
}

.friendLabel {
  display: inline-block;
  margin: 0 2px;
}

.friendLabel ~ span {
  margin-left: 2px;
}

.invite-friends-card {
  max-width: 470px;

  & .invite-friends-img {
    max-width: calc(100% + 64px);
    margin-top: -33px;
    margin-left: -32px;
    margin-right: -32px;
  }

  & .invite-friends-card-heading {
    font-size: 24px;
    margin: 20px 0 4px 0;
  }

  & .invite-friends-card-subhead {
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #626366;
  }

  & .referral-label {
    font-size: 14px;
    line-height: 1.5;
    color: #30332c;
    margin-bottom: 4px;
  }

  & .connect-coworker-p {
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: center;
    color: #30332c;
  }

  & .friend-request-error {
    margin-top: 24px;
  }

  & .hr-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #626366;
    font-size: 12px;
    margin: 24px 0;
  }

  & .hr-text::before,
  & .hr-text::after {
    content: '';
    flex-grow: 1;
    background: #d5d6d8;
    height: 1px;
    font-size: 0px;
    line-height: 0px;
    margin: 0px 8px;
  }

  & .invite-friend-form {
    max-width: 400px;
    margin: 16px auto 0 auto;

    & .invite-friend-input {
      position: relative;
      width: 100%;

      & .nutrient__placeholder {
        font-size: 16px;
        font-weight: 500;
        font-style: italic;
        line-height: 1.31;
        color: #a8aebd;
      }

      & .autocomplete-option {
        padding: 12px 8px;
        border-bottom: solid 1px rgba(206, 207, 209, 0.33);

        & .avatar-container {
          display: inline-block;
          vertical-align: middle;
          padding: 0px 4px;
        }

        & .friend-label {
          display: inline-block;
          font-weight: bold;
          font-size: 16px;
          color: var(--main-ocean-blue);
          padding: 0px 4px;
        }

        & .add-icon {
          display: inline-block;
        }
      }

      & .autocomplete-option:hover {
        background: var(--autocomplete-base);
      }

      & .autocomplete-option__focused {
        background: var(--autocomplete-base);
      }
    }
    & .invite-friend-button,
    & .invited-friend-button {
      display: block;
      margin: 20px auto 0 auto;
      transition: all 0.5s;
    }

    & .invite-friend-button:disabled,
    & .invited-friend-button:disabled {
    }

    & .invited-friend-button {
      background-color: var(--nu-success);
    }

    & div.nutrient__control {
      border: solid 3px rgba(206, 207, 209, 0.4);
      border-radius: 0px;
      transition: border-color var(--nu-transition);
      background: var(--nu-light-grey-blue);

      & > .nutrient__indicators {
        padding: 0px 8px;
        & > .nutrient__indicator-separator {
          display: none;
        }
      }

      & > .nutrient__placeholder {
        font-size: 18px;
      }

      & .nutrient__clear-indicator {
        border: none;
        height: 36px;

        & svg:hover {
        }
      }
    }

    & div.nutrient__control--is-focused {
      border-color: var(--nu-secondary);
    }
  }

  & .invite-friends-referral {
    max-width: 350px;
  }
}

.friend-manager {
  & .suggested-friends-container {
    font-size: 24px;
    margin-bottom: 16px;

    & .myfriends-divider {
      width: 100%;
      margin: 32px 0;
    }

    & .suggested-friend-name {
      & > .successIcon {
        display: block;
        margin-top: 16px;
        font-size: 24px;
        color: var(--nu-success);
        overflow: hidden;
      }

      & > .spinner-xs {
        display: inline-block;
        margin-top: 16px;
      }

      & > .friendsLabel {
        display: block;

        & .friendDetails {
          display: block;
          text-align: center;
          margin-top: 16px;
          padding: 0px;
          font-size: 18px;
          font-weight: 500;
          color: var(--main-ocean-blue);
          white-space: nowrap;
        }
      }

      & .add-friend-btn {
        border-bottom: 2px solid var(--nu-secondary);
        bottom: 6px;
        padding-right: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      & .loading::after {
        animation: loading 500ms ease infinite;
      }

      & .add-friend-btn:hover {
        color: var(--ocean);
      }
    }

    & .suggested-friends {
      text-align: center;
      overflow: hidden;

      & > .suggested-friend {
        padding: 16px 0px;
      }
    }
  }

  & .friend-invites,
  & .friend-invites-title {
    margin-bottom: 16px;

    & .friend-request-row {
      padding: 8px 0px;

      & .friendDetails {
        align-items: center;
        display: flex;
        /* font-weight: bold; */
        margin-left: 40px;
      }

      @media screen and (min-width: 769px) {
        margin: 0 24px;
      }

      & .friend-request {
        & .friendDetails {
          & > div {
            s & .friendEmail {
              color: var(--base-friends);
              font-size: 11px;
              font-weight: normal;
              margin-top: 8px;
              text-overflow: ellipsis;
              word-break: normal;
            }
          }
        }
      }

      & .invites-sent {
        font-size: 14px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        & .cancel-invite {
          margin-left: 16px;
          opacity: 0.75;

          & > img {
            vertical-align: middle;
          }
        }

        & .cancel-invite:hover {
          opacity: 1;
        }
      }
    }
  }

  & .my-friends-title {
    margin-bottom: 8px;

    & .myfriends-divider {
      width: 100%;
    }
  }

  & .my-friends-avatars {
    & > .removing-friend {
      opacity: 0.4;
    }

    & > div {
      padding: 20px 0.5rem;
      text-align: center;
      position: relative;

      & .friend-remove {
        font-size: 14px;
        position: absolute;
        top: 0px;
        right: 16px;
        opacity: 0;
        transition: all 0.1s ease;
      }
    }

    & > div:hover {
      & > .friend-remove {
        opacity: 0.75;
      }

      & > .friend-remove:hover {
        opacity: 1;
      }
    }

    & .new-friend-avatar {
      text-align: center;
      font-weight: 500;
      color: var(--new-friend-base);

      & > div {
        margin-top: 16px;
      }
    }

    & .friendsLabel {
      display: block;
      text-align: center;

      & > .friendDetails {
        display: block;
        text-align: center;
        margin-top: 16px;
        padding: 0px;
        font-size: 20px;
        font-weight: 500;
        color: var(--main-ocean-blue);

        & > div {
        }

        & .friendEmail {
          margin-top: 8px;
          font-size: 11px;
          font-weight: normal;
        }
      }
    }
  }
}

.myfriends-divider {
  border-top: 2px solid #f2f2f2;
  margin: 32px 0;
}

.roboto {
}

.move-leave {
  opacity: 1;
  max-width: 100%;
}

.move-leave-active {
  max-width: 0%;
  opacity: 0.01;
  transition: all 300ms ease;
}

.foodsby-tooltip {
  padding: 6px 10px !important;
  font-size: 11px;
  background-color: var(--base-friends) !important;
}

.foodsby-tooltip.__react_component_tooltip.place-left::after {
  border-left: 0 solid var(--base-friends) !important;
}

.foodsby-tooltip.__react_component_tooltip.place-right::after {
  border-right: 0 solid var(--base-friends) !important;
}

.foodsby-tooltip.__react_component_tooltip.place-top::after {
  border-top: 0 solid var(--base-friends) !important;
}

.foodsby-tooltip.__react_component_tooltip.place-bottom::after {
  border-bottom: 0 solid var(--base-friends) !important;
}
