.restaurantshowcase {
  min-width: 320px;
  min-height: 1px;
  align-content: center;
}

.restaurantshowcase > .row {
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-around;
}

.restaurantshowcase > .row > div {
  padding: 24px;
  max-width: 180px;
}

.icon-row {
  padding: 16px;
}

.icon-logo-container > img {
  height: auto;
  width: auto;
  max-width: 100%;
  min-height: 1px;
}

.restaurantshowcase-title {
  text-align: center;
}

@media (max-width: 768px) {
  .icon-logo-container > img {
    padding: 16px 0px;
  }

  .restaurantshowcase-title {
    padding: 0px 24px;
  }
}
