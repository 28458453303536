.notification-header {
  text-align: left;
  display: block;
  font-size: 20px;
  font-weight: 600;
}

.notification-preference-type {
  margin-top: 8px;
}

.profile-image-container {
  & .error {
    margin-top: 8px;
  }
}

.edit_profile_img_action {
  display: inline-block;
  margin-bottom: 16px;

  & > .drag-active {
    & > img {
      filter: blur(5px) grayscale(50%);
      opacity: 0.5;
    }
  }
}

.profile-image {
  position: relative;
  overflow: hidden;
  clip-path: circle(63px at center);
  cursor: pointer;
  border-radius: 50%;
  height: 120px;
  width: 120px;

  & > img {
    border-radius: 50%;
    border: 4px solid lightgrey;
  }
}

.profile-image:hover,
.drag-active {
  border: 2px dashed #000000;
  & > .MuiAvatar-circular {
    filter: blur(5px);
    opacity: 0.5;
    height: 120px;
    width: 120px;
    border: none;
    border-radius: initial;
  }
}

.loading.profile-image:hover,
.loading.drag-active {
  border: none;
}

.profile-image::after {
  content: 'EDIT';
  font-family: 'Roboto', sans-serif;
  background-color: #000000;
  color: #fff;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1px;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 32px;
}

.profile-image:hover::after,
.drag-active::after {
  height: 0px;
  content: '';
}

.profile-image:hover::before,
.drag-active::before {
  content: 'Drag & Drop\Aor\AUpload Image\A\AJPG, PNG';
  font-family: 'Roboto', sans-serif;
  color: #000000;
  position: absolute;
  font-size: 12px;
  text-align: center;
  z-index: 1;
  top: 20%;
  left: 20%;
  white-space: pre;
  line-height: 14px;
}

.profile-image.loading::after {
  content: '';
  background-color: initial;
  height: 100%;
  border: 4px solid var(--nu-light-grey);
  border-top-color: #000000;
  border-radius: 100%;
  animation: spinner-profile-img 0.5s linear infinite;
}

@keyframes spinner-profile-img {
  to {
    transform: rotate(360deg);
  }
}

.profile-image.loading {
  & > .MuiAvatar-circular {
    filter: blur(5px);
    opacity: 0.5;
    height: 120px;
    width: 120px;
  }
}

.profile-image.loading:hover::after,
.drag-active.loading::after {
  content: '';
  background-color: initial;
  height: 100%;
  border: 4px solid var(--nu-light-grey);
  border-top-color: #000000;
  border-radius: 100%;
  animation: spinner-profile-img 0.5s linear infinite;
}

.profile-image.loading:hover::before,
.drag-active.loading::before {
  display: none;
}

.ProCard-header {
  /* border-bottom: 1px solid lightgrey; */
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.ProCard-label {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 17px;
}

.ProCard-inputLabel label {
  font-size: 12px;
  margin-bottom: 4px;
}

.ProCard-text {
  margin-bottom: 27px;
}

.SubscriptionUpgrade-header {
  margin-bottom: 8px;
}

.SubscriptionUpgrade-text {
  margin-bottom: 8px;
}

.SubscriptionUpgrade-cta {
  margin-top: 16px;
}
