@import '../../node_modules/react-table/react-table.css';
/* Override React Table Styling */
.ReactTable {
  border: none;

  & .rt-thead.-header {
    box-shadow: none;
  }

  & .rt-tbody {
    & .rt-tr-group {
      border-bottom: solid 1px rgba(0, 0, 0, 0.5);
      padding: 4px 0px;
    }

    & > .rt-tr-group:nth-child(1) {
      border-top: solid 1px rgba(0, 0, 0, 0.5);
    }

    & > .rt-tr-group:last-child {
      border-bottom: solid 1px rgba(0, 0, 0, 0.5);
    }
  }

  & .rt-thead,
  & .rt-tfoot {
    font-size: 1.25em;
    font-weight: 500;

    & .rt-th {
      box-shadow: none;
      border: none;
      user-select: text;
    }

    & .rt-th:focus {
      outline: none;
    }

    & .rt-td {
      box-shadow: none;
      border: none;
    }

    & .rt-tr {
      text-align: left;
    }
  }
}
