.restaurant-login {
  background: var(--nu-secondary);
  color: white;
  text-align: center;
  padding: 60px;

  & > .restaurant-login_title {
    text-transform: uppercase;
  }
  & > .restaurant-login_button {
    background: white;
    border-color: white;
    margin-top: 8px;

    &:after {
      border-color: white;
    }

    &:hover,
    &:focus {
      background: var(--nu-tertiary);
      border-color: var(--nu-tertiary);
    }
  }
}
