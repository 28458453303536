#root {
  height: 100%;
}

.pt-1 {
  padding-top: 1em;
}

.pb-1 {
  padding-bottom: 1em;
}

.pt-15 {
  padding-top: 1.5em;
}

.pb-15 {
  padding-bottom: 1.5em;
}

.mb-15 {
  margin-bottom: 1.5em;
}
