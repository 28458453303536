:root {
  --gutter: 16px;
  --nu-primary: #ee3c23;
  --nu-primary-alt: #d43620;
  --nu-secondary: #000000;
  --nu-secondary-alt: #363636;
  --nu-tertiary: #ec8424;
  --nu-tertiary-alt: #cb6b11;
  --nu-grey: #757575;
  --nu-light-grey: #d9d9d9;
  --nu-disabled: #949494;
  --nu-success: green;
  --nu-transition: 0.3s ease;
}

body {
  font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  margin: 0;
}

.title {
  font-weight: 600;
  font-size: 32px;
}
.description {
  margin-bottom: 16px;
}
.pagination {
  max-width: 600px;
  margin: 0 auto;
}
ul {
  display: initial;
}
small {
  font-size: smaller;
}
.hidden {
  display: none;
}

.content {
  margin-top: 32px;
  min-height: calc(100vh - 264px) !important;
}

/* Hacks to handel NutrientV2 overrides */
.home,
.-container {
  & p,
  & a {
    font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif !important;
    line-height: initial;
  }
  & a:hover {
    text-decoration: none !important;
  }
  /* Hack for global a styles */
  & .no-hover:hover {
    color: #30332c;
  }

  & li {
    margin-bottom: initial;
    list-style: none;
  }
}
.main {
  height: auto !important;
}

.grecaptcha-badge {
  visibility: hidden;
}
