.referral-container {
  & h5 {
    max-width: 440px;
    margin: auto;
    text-align: left;
    margin-bottom: 4px;
  }

  & p {
    font-size: 14px;
    max-width: 440px;
    margin: auto;
    margin-bottom: 16px;
    line-height: 20px;
    text-align: left;
  }

  & .share-container {
    height: 50px;
    max-width: 440px;
    margin: auto;
    display: flex;
    min-height: auto;
    border-radius: 4px;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    justify-content: center;
    border: black solid 2px;

    & .link {
      font-size: 14px;
      word-break: break-word;
      overflow: hidden;
      transition: transform 0.3s ease;
      transform-origin: right;
      white-space: nowrap;
      user-select: all;
      text-overflow: ellipsis;
      max-width: 290px;
      color: black;
      text-align: center;
    }

    & .link-visible {
      transform: scaleX(1);
    }

    & .link-collapsed {
      transform: scaleX(1);
    }
  }

  & .error-container {
    max-width: 440px;
    margin: auto;
  }

  & .error {
    padding-top: 16px;
    max-width: 440px;
    margin: auto;
    text-align: left;
  }

  & .copied-text {
    display: inline-block;
    margin: 8px auto auto auto;
    font-size: 13px;
    text-align: left;
    font-family: 'Roboto', sans-serif;
    color: var(--cyan-dark);
    border-bottom: 1px solid var(--cyan-dark);
  }
}

.referral {
  margin-bottom: 8px;

  & .referral-link {
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--nu-grey);
    user-select: all;
    white-space: nowrap;
  }

  & .share-container {
    width: initial;
    max-width: initial;
    text-align: left;
  }
}

.referral-confirmation {
  max-width: 80%;
  margin: auto;
}
