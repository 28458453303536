.success-notification,
.failure-notification {
  border-bottom: 1px solid #d5d6d8 !important;
  border-left: 1px solid #d5d6d8 !important;
  border-radius: 4px !important;
  border-right: 1px solid #d5d6d8 !important;
  padding: 12px !important;
  position: relative;
  background: white;

  & strong {
    padding-right: 4px;
  }
}

.success-notification {
  border-top: 4px solid #cfe59c !important;
}

.failure-notification {
  border-top: 4px solid #eeaea5 !important;
}

.notification-message {
  margin: 0 16px 0 40px;
  margin-left: 40px;
  margin-right: 16px;
  margin-bottom: 0;
}

.success-notification-icon {
  color: #9fcb3a;
  left: 12px;
  position: absolute;
}

.failure-notification-icon {
  color: #f05c35;
  left: 12px;
  position: absolute;
}

.notification-close {
  height: 40px;
  opacity: 0.5;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &:before,
  &:after {
    content: ' ';
    background-color: #363636;
    height: 20px;
    left: 50%;
    position: absolute;
    top: 25%;
    width: 1px;

    &:hover {
      transform: rotate(180deg);
      animation: 2s ease-in-out all;
    }
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
