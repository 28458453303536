.searchWrapper {
  width: 100%;
  max-width: 720px;
  margin: auto;
  display: flex;
  justify-content: center;
}
.searchIcon {
  max-height: 42px;
  font-size: 28px;
  background: var(--nu-light-grey-blue);
  border-bottom: 2px solid gray;
  padding: 5px 0 0 8px;
}
.searchBox {
  flex-grow: 1;

  & input {
    padding-right: 24px;
    background-color: white;
    color: #30332c;
  }
}
.searchButton {
  margin-top: 8px;
  & > button.locationsearch-button,
  & > button.locationsearch-button[disabled] {
    display: flex;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    margin: 0;
    height: 40px;
  }
}
.searchLocation {
  cursor: pointer;
  padding: 16px 8px;
  margin: 0 8px;
  border-bottom: 2px solid white;
  border-top: 2px solid var(--nu-light-grey);
  border-left: 2px solid white;
  border-right: 2px solid white;

  &:last-child {
    border-bottom-color: var(--nu-light-grey);
  }

  &[data-selected='true'] {
    border-color: var(--nu-secondary);
    box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.12);
  }
}
.searchLocationName {
  font-weight: bold;
}
.searchLocationInfoWindow {
  &:hover {
    cursor: pointer;
  }
}
.searchLocationsList {
  max-height: 650px;
  overflow: auto;
  scroll-behavior: smooth;

  & > .searchLocationListItem {
    &:hover,
    &:focus {
      border-color: var(--nu-secondary);
    }
  }
}
.searchLocationsList-flash {
  background: transparent;

  animation: flash 1s forwards linear normal;
}
@keyframes flash {
  0% {
    background: var(--nu-secondary);
  }
  100% {
    background: transparent;
  }
}
